import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import userBOStore from "./stores/userBOStore";

class ActivityLogError extends Error {
  public readonly tabNumber;

  constructor(
    public readonly email: string,
    public readonly logs: string[],
  ) {
    super();
    this.tabNumber = activityTabNumber;
  }
}

const activityTabNumberStoreKey: StorageKeyInfo<number> = {
  key: "Logging_ActivityTabNumber",
  pathIndependant: true,
  userIndependant: true,
};

let activityTabNumber = LocalStorageUtil.get(activityTabNumberStoreKey, 0);
if (activityTabNumber > 100) {
  activityTabNumber = 0;
}
LocalStorageUtil.set(activityTabNumberStoreKey, activityTabNumber + 1);

let logs: string[] = [];

// Not in Logging.ts to avoid circular dependencies
Logging.activity = function (message, flush): void {
  const email = userBOStore.get()?.email?.toLowerCase();
  if (
    !email ||
    !import.meta.env.VITE_ACTIVITYLOGEMAILS?.toLowerCase().includes(email) ||
    message?.toLowerCase().includes("activitylogerror")
  ) {
    return;
  }

  // eslint-disable-next-line no-restricted-syntax
  const completeMessage = `${new Date().toUTCString()} | ${message}`;
  if (window.console) {
    console.log(completeMessage);
  }
  logs.push(completeMessage);

  if (flush) {
    Logging.error(new ActivityLogError(email, logs));
    logs = [];
  }
};
