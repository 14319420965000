import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import userStore from "@reservauto/react-shared/stores/userStore";
import { User, UserProfile as OidcUserProfile } from "oidc-client-ts";
import { ECity } from "../../areas/general/dto";
import cityStore from "./cityStore";

export interface UserProfile extends OidcUserProfile {
  ADB2C_UserUniqueId?: string;
  branchId: number;
  city: ECity;
  cityId: number;
  displayName: string;
  language: string;
  languageId: number;
  security_client_id: string;
}

export class UserBOStore extends StateStoreBase<UserProfile | null> {
  private userInfo: UserProfile | null = null;

  public get(): UserProfile | null {
    return this.userInfo;
  }

  public async populate(authenticatedUser: User): Promise<UserProfile> {
    const profile = authenticatedUser.profile as UserProfile;

    this.userInfo = profile;
    userStore.populate({
      accessToken: authenticatedUser.access_token,
      uniqueUserId: `${profile.ADB2C_UserUniqueId}_${profile.branchId}`,
    });

    this.notifySubscribers();

    return profile;
  }

  public resolveCity(): void {
    if (this.userInfo?.cityId !== undefined) {
      const cityId = Number(this.userInfo.cityId.toString());
      const city =
        cityStore.get()?.find((c) => c.cityId === cityId)?.city ??
        ECity.Unknown;

      this.userInfo.city = city;
    }
  }
}

const userBOStore = new UserBOStore();
export default userBOStore;
